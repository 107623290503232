import './hero-slider.scss';
// import { getClip } from '../../utilities/js/clip';

class HeroSlider {
    constructor($element) {
        this.$heroSliderRoot = $element;
        this.slider = [];

        this.$slideImages =
            this.$heroSliderRoot.querySelectorAll('.hero-image');
    }

    initialize() {
        // [].slice.call(this.$slideImages).forEach((image) => {
        //     image.style.clipPath = getClip(
        //         image.dataset.overlapPosition,
        //         image.dataset.overlapWidth
        //     );
        // });
    }
}

export { HeroSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $heroSliders = $context.querySelectorAll(
            '[data-hero-slider="root"]'
        );
        if ($heroSliders.length > 0) {
            for (let i = 0; i < $heroSliders.length; i++) {
                const $heroSlider = new HeroSlider($heroSliders[i]);
                $heroSlider.initialize();
            }
        }
    }
});
